<template>
  <div class="section section__company">
    <div class="section__wrapper">
      <div class="title-with-icon title-with-icon_menu mb-5">
        <div class="title-with-icon__icon title-with-icon__icon_back"></div>
        <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
        <div class="title-with-icon__delimeter">/</div>
        <div class="title-with-icon__title">Доступная среда</div>
      </div>

      <div class="mb-md-12">
        <div class="content_18">
          <h3>О специально оборудованных учебных кабинетах</h3>
          <p itemprop="purposeCab">отсутствует/не применимо</p>
          <h3>Об объектах для проведения практических занятий, приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья</h3>
          <p itemprop="purposePrac">отсутствует/не применимо</p>
          <h3>О библиотеке(ах), приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья</h3>
          <p itemprop="purposeLibr">отсутствует/не применимо</p>
          <h3>Об объектах спорта, приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья</h3>
          <p itemprop="purposeSport">отсутствует/не применимо</p>
          <h3>О средствах обучения и воспитания, приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья</h3>
          <p>отсутствует/не применимо</p>
          <h3>Об обеспечении беспрепятственного доступа в здания образовательной организации</h3>
          <p itemprop="ovz">Для инвалидов и лиц с ограниченными возможностями здоровья, имеющих нарушения опорно-двигательного аппарата (при наличии таких обучающихся): обеспечены материально-технические условия беспрепятственного доступа обучающихся в учебные помещения, столовые, и другие помещения Школы экспорта, а также пребывания в указанных помещениях (наличие пандусов, поручней, расширенных дверных проемов, лифтов, локальное понижение стоек-барьеров; наличие специальных кресел и других приспособлений).</p>
          <h3>О специальных условиях питания</h3>
          <p itemprop="meals">отсутствует/не применимо</p>
          <h3>О специальных условиях охраны здоровья</h3>
          <p itemprop="health">отсутствует/не применимо</p>
          <h3>О доступе к информационным системам и информационно-телекоммуникационным сетям, приспособленным для использования инвалидами и лицами с ограниченными возможностями здоровья</h3>
          <p itemprop="comNetOvz">Имеется <a itemprop="erListOvz" href="https://exportedu.ru/accessibility">альтернативная версия для слабовидящего</a> официального портала Школы экспорта в сети «Интернет»</p>
          <h3>Об электронных образовательных ресурсах, к которым обеспечивается доступ инвалидов и лиц с ограниченными возможностями здоровья</h3>
          <p>Имеется альтернативная версия для слабовидящего официального портала Школы экспорта в сети «Интернет»</p>
          <h3>О наличии специальных технических средств обучения коллективного и индивидуального пользования</h3>
          <p itemprop="techOvz">отсутствует/не применимо</p>
          <h3>О наличии условий для беспрепятственного доступа в общежитие, интернат</h3>
          <p itemprop="hostelInterOvz">отсутствует/не применимо</p>
          <h3>О количестве жилых помещений в общежитии, интернате, приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья</h3>
          <p itemprop="hostelNumOvz">отсутствует/не применимо</p>
          <h3>О заключенных и планируемых к заключению договорах с иностранными и (или) международными организациями по вопросам образования и науки (при наличии)</h3>
          <p>отсутствует/не применимо</p>
          <h3>О международной аккредитации образовательных программ (при наличии)</h3>
          <p>отсутствует/не применимо</p>
        </div>
      </div>

      <div class="my-5 d-block d-md-none">
        <a class="history-back" href="/sveden">Вернуться</a>
      </div>

      <div class="section__subsection_blue section__company-nav">
        <div class="section__subsection-wrapper">
          <company-navigation/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoVacancy",
  components: {CompanyNavigation},
  mounted() {
    this.$parent.init()
  }
}
</script>
<style lang="scss">
  .content_18 {
    h3 {
      font-size: 22px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
</style>